<template>
  <Preloader :loading="loading" />
  <Preloader :loading="cpeLoading" />

  <div class="cpe padding-x" v-if="Object.keys(event).length">
    <div class="printing">
      <div class="d-flex" style="align-items: center">
        <img
          src="@/assets/img/logo_black.png"
          alt="exploit logo"
          class="footer__logo mb-5"
        />
      </div>
      <div class="d-flex" style="align-items: center">
        <Heading type="h3" text-align="center" noDecoration class="mb-3">
          {{ event.name }}
        </Heading>
      </div>
      <h3>{{ event.location.city }} - {{ getTime(event.start, "day") }}</h3>
      <h3 v-if="ticket">{{ ticket.first_name }} {{ ticket.last_name }}</h3>
    </div>
    <Heading
      type="h3"
      text-align="center"
      noDecoration
      class="mb-11 cpe__title"
    >
      {{ event.name }} CPE report
    </Heading>
    <div v-if="noCPE" class="cpe__success">
      Looks like no sessions have been selected… Please choose talks that you
      have attended
    </div>
    <div v-if="cpeSuccess" class="cpe__success">
      Your CPE report created successfully
    </div>
    <div v-if="cpeError" class="cpe__error">
      {{ cpeError }}
    </div>
    <div v-if="cpe" class="mb-20">
      <div class="mb-3 cpe__print-btn">
        <Button v-show="!slots" class="px-10 mr-3" @click="getSlots">
          edit
        </Button>
        <Button white class="px-10" @click="printWindow"> print </Button>
      </div>
      <h3>CPE report for {{ event.name }}</h3>

      <div v-for="talk in cpe" :key="talk.id" class="cpe__form">
        <div class="cpe__form-item pl-4">
          {{ getTime(talk.start_time, "time") }}
          {{ talk.title }} -
          <i>
            {{ talk.submission.bio.first_name }}
            {{ talk.submission.bio.last_name }}
          </i>
        </div>
        <div class="cpe__form-time">
          {{ getCreditsAmount(talk.start_time, talk.end_time) }} CPE
        </div>
      </div>
      <div class="cpe__form">
        <div class="cpe__form-item pl-4"><b>Total</b></div>
        <div class="cpe__form-time">
          <b>{{ getCreditsSum() }} CPE</b>
        </div>
      </div>
    </div>
    <Preloader :loading="formLoading" />
    <div v-if="slots" id="cpe-form">
      <h3>
        Please select talks that you have attended during {{ event.name }} to
        generate your CPE report
      </h3>
      <div
        v-for="[time, list] in Object.entries(slots)"
        :key="time"
        class="cpe__form"
      >
        <div class="cpe__form-time">
          {{ getTime(time, "time") }}
        </div>
        <div class="cpe__form-item">
          <div v-for="talk in list" :key="talk.id">
            <input
              :id="talk.id"
              type="checkbox"
              v-model="chosenTrackBySlot"
              :value="talk"
              class="ml-2"
            />
            <label :for="talk.id" class="ml-3">
              {{ talk.title }}
              <span class="cpe__form-description">
                {{ talk.submission.bio.first_name }}
                {{ talk.submission.bio.last_name }} -
                {{ talk.submission.bio.organization }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <Button
        class="mt-6 px-10"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="saveCredits"
      >
        submit
      </Button>
    </div>
    <div class="printing">
      <div class="printing__contact">
        <h3>Contact us</h3>
        <div>(907) 290-7660</div>
        <div>{{ config.helpEmail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import Button from "@/components/common/Button";
import config from "../../../public/config.json";

export default {
  name: "cpeCredits",
  props: {},
  components: {
    Preloader,
    Button,
    Heading,
  },
  data: () => ({
    config,
    loading: false,
    cpeLoading: false,
    formLoading: false,
    ticketLoading: false,
    isSubmitting: false,
    noCPE: false,
    cpeSuccess: false,
    cpeError: false,
    cpe: null,
    slots: null,
    ticket: null,
    chosenTrackBySlot: [],
  }),
  computed: {
    ...mapGetters({
      event: "getCurrentEventDetails",
    }),
  },
  created() {
    if (
      !Object.keys(this.event).length ||
      this.event.id !== this.$route.params.id
    ) {
      this.loading = true;
      this.getEvent(this.$route.params.id)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.getTicket();
    this.getCpe();
  },
  methods: {
    ...mapActions(["getEvent", "GET", "POST"]),
    printWindow() {
      window.print();
    },
    getSlots() {
      this.formLoading = true;
      return this.GET({
        route: `/public/event/${this.$route.params.id}/schedule_sessions`,
      })
        .then((resp) => {
          this.slots = resp.data;
          this.cpe = null;
          this.formLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.formLoading = false;
        });
    },
    getCpe() {
      this.cpeLoading = true;
      return this.GET({
        route: `/ticketing/public/event/${this.$route.params.id}/ticket/${this.$route.params.token}/cpe_report`,
      })
        .then((resp) => {
          if (resp.data) {
            this.cpe = resp.data.cpe_report;
            this.chosenTrackBySlot = resp.data.cpe_report;
          }
          this.cpeLoading = false;
        })
        .catch(() => {
          this.noCPE = true;
          setTimeout(() => {
            this.noCPE = false;
          }, 5000);

          this.getSlots();
          this.cpeLoading = false;
        });
    },
    getTime(dateString, modifyer) {
      const startDate = new Date(dateString);
      const options =
        modifyer === "time"
          ? {
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          : {
              month: "long",
              day: "2-digit",
              year: "numeric",
            };
      return startDate.toLocaleString("en-US", options);
    },
    getTicket() {
      this.ticketLoading = true;
      return this.GET({
        route: `/ticketing/public/event/${this.$route.params.id}/ticket/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.ticket = resp.data;
          console.log(resp);
          this.ticketLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.ticketLoading = false;
        });
    },
    getCreditsAmount(start, end) {
      const duration = Math.abs(new Date(end) - new Date(start)) / 60000;
      return duration / 60;
    },
    getCreditsSum() {
      let arrOfMinutes = this.cpe.map((el) => {
        return (
          Math.abs(new Date(el.end_time) - new Date(el.start_time)) / 60000
        );
      });
      let result = arrOfMinutes.reduce((a, b) => {
        return a + b;
      }, 0);
      return result / 60;
    },
    saveCredits() {
      this.cpeError = false;
      this.isSubmitting = true;

      this.POST({
        route: `/ticketing/public/event/${this.$route.params.id}/ticket/${this.$route.params.token}/cpe_report`,
        data: { talks: this.chosenTrackBySlot },
      })
        .then(() => {
          this.slots = null;
          this.isSubmitting = false;
          window.scrollTo(0, 0);
          this.cpeSuccess = true;
          setTimeout(() => {
            this.cpeSuccess = false;
          }, 3000);
          this.getCpe();
        })
        .catch((error) => {
          this.cpeError =
            error.response.data.msg || "Sorry, something went wrong";
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.cpe {
  padding-top: 108px;

  &__form {
    display: flex;

    &-item {
      color: $white;
      width: 100%;
      padding: 5px;
      border: 1px solid $dark-gray;
    }

    &-time {
      width: 16%;
      text-align: center;
      padding: 5px;
      border: 1px solid $dark-gray;
    }

    &-description {
      font-size: 12px;
    }
  }

  &__error {
    color: $error-red;
    margin-bottom: 10px;
  }

  &__success {
    color: $red;
    margin-bottom: 10px;
  }

  &__print-btn {
    display: flex;
    justify-content: flex-end;
  }
}

.printing {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  margin-top: -45px;

  img {
    width: 90px;
    margin-right: 40px;
  }

  &__contact {
    margin-left: 55%;
    text-align: end;
  }
}

@media print {
  .printing {
    display: flex;
  }

  #cpe-form,
  .cpe__title,
  .cpe__print-btn {
    display: none;
  }
}
</style>
